import React from 'react'
import { graphql } from 'gatsby'

import { LanguageContextProvider } from '@/base/context/LanguageContext'
import { LocationContextProvider } from '@/base/context/LocationContext'

import { SEO } from '@/components/elements/SEO'
import { Header } from '@/components/sections/Header'
import { Footer } from '@/components/sections/Footer'
import { Section } from '@/components/modules/Section'

const WordpressPageTemplate = ({
  data: {
    page: { header, footer },
  },
  pageContext,
  ...rest
}) => (
  <LocationContextProvider {...pageContext}>
    <LanguageContextProvider {...pageContext}>
      <SEO title={pageContext.title?.rendered} yoastHead={pageContext.yoast_head} />
      <Header {...(header || {})} languageToggleVariant="bar" />
      <main>
        <Section>{pageContext.content.rendered}</Section>
      </main>
      <Footer {...(footer || {})} />

      <pre style={{ overflow: 'scroll' }}>{JSON.stringify(rest, null, 2)}</pre>
    </LanguageContextProvider>
  </LocationContextProvider>
)

export default WordpressPageTemplate

export const WordpressPageQuery = graphql`
  query WordpressPageQuery {
    page: contentfulWordpressPageLayout {
      header {
        ... on ContentfulHeader {
          ...FragmentHeader
        }
      }
      footer {
        ... on ContentfulFooter {
          ...FragmentFooter
        }
      }
    }
  }
`
